<template>
    <b-tooltip :target="target">
        <div class="patient-name">
            {{ patient.name }}
        </div>
        <div class="calendar">
            <Calendar fill="#305BF2" class="icon fill"/>
            <span class="value" v-if="moment().diff(moment(patient.birthday),'days') <= 30 " >
              {{ moment().diff(moment(patient.birthday),'days') }} dias
            </span>
            <span class="value" v-else-if="moment().diff(moment(patient.birthday),'month') > 1 && moment().diff(moment(patient.birthday),'month') < 12" >
              {{ moment().diff(moment(patient.birthday), 'month') }} meses
            </span>
            <span class="value" v-else-if="!patient.birthday" >
              Idade (Não informado)
            </span>
            <span class="value" v-else >
              {{ moment().diff(moment(patient.birthday), 'years') }} anos
            </span>
        </div>

        <div v-if="validarIdade">
            <Responsible fill="#305BF2" class="icon fill"/>
            {{ patient.nome_mae || patient.nome_pai ? (patient.nome_mae ? patient.nome_mae : patient.nome_pai) : "Responsáveis (Não informado)"}}
        </div>

        <div v-for="healthPlan in healthPlans" :key="healthPlan.id">
          <Plan viewBox="0 0 26 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="#305BF2" class="icon fill" />
          {{ healthPlan.health_plan.company_name ? healthPlan.health_plan.company_name : "Plano (Não informado)"}}
        </div>

        <div v-if="validarIdade && patient?.profissao">
          <Job class="icon fill stroke"/>
            {{ patient?.profissao }}
        </div>

        <div v-if="!validarIdade">
          <Job class="icon fill stroke"/>
            {{ patient.profissao ? patient.profissao : "Profissão (Não informado)" }}
        </div>

        <div >
          <Location class="icon fill stroke"/>
            {{ patient?.endereco?.city ? (patient?.endereco?.city +'-'+ patient?.endereco?.state) : "Localização (Não informado)" }}
        </div>

        <div>
            <MoreInformation fill="#305BF2" class="icon fill"/>
              {{ patient.mais_informacoes ? patient.mais_informacoes : "Mais informações (Não informado)" }}
        </div>

        <div v-if="patient?.religiao">
            <Religion fill="#305BF2" class="icon fill"/>
              {{ patient.religiao ? patient.religiao : "Religião (Não informado)" }}
        </div>
    </b-tooltip>
</template>

<script>
import moment from 'moment'
import { getCurrentClinic } from '../../utils/localStorageManager'

export default {
  name: 'tooltipPatient',
  props: {
    target: String,
    patient: {
        type: Object,
        default: () => ({}),
    },
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      healthPlans: [],
    }
  },
  components: {
    Calendar: () => import ('@/assets/icons/calendar.svg'),
    Religion: () => import ("@/assets/icons/religion.svg"),
    Job: () => import ("@/assets/icons/job.svg"),
    Location: () => import ("@/assets/icons/location.svg"),
    Plan: () => import ("@/assets/icons/health-plan.svg"),
    Responsible: () => import ("@/assets/icons/responsible.svg"),
    MoreInformation: () => import ("@/assets/icons/more-information.svg"),
  },
  computed: {
    validarIdade() {
      const idade = this.patient?.birthday ? this.moment().diff(this.patient?.birthday, 'years') : '-'
      return idade < 18 
    }
  },
  methods: {
    moment,
    async getPatientHealthPlans(){
      try {
        const res = await this.api.getPatientPersonHealthPlans(this.patient.id, this.clinic.id)
        this.healthPlans = res.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
  watch: {
    'patient.id' : {
      async handler (value) {
        if(!value) return
        await this.getPatientHealthPlans() 
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-name {
    padding: 3px 0px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #525C7A;
    border-bottom: 1px solid var(--neutral-200);
  }
.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding-top: 2px;

  &.fill {
    display: inline-block;
  }

  &.stroke {
    stroke: #305BF2 !important
  }
}

.calendar {
  padding-top: 5px;
}
</style>